import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { useAppContext } from 'contexts/AppContext';

const Help = () => {
    const { isMobile } = useAppContext();
    const helpLinks = [
        {
            title: 'Chat',
            icon: 'icon-message',
            link: `${process.env.REACT_APP_EPAYS3_NEWJETNET_URL}/sites/team-member-services/knowledgebase/135931/money-and-attendance`,
        },
        {
            title: 'Open a case',
            icon: 'icon-pencil',
            link: `${process.env.REACT_APP_EPAYS3_OPENCASE_URL}`,
        },
        {
            title: 'My cases',
            icon: 'icon-page',
            link: `${process.env.REACT_APP_EPAYS3_MYCASE_URL}`,
        },
        {
            title: 'Call',
            icon: 'icon-phone',
            number: '800-447-2000',
        },
    ];

    const helpLinkComponents = helpLinks.map((link) => {
        return (
            <Row
                key={link.title}
                className='px-3 py-2'
            >
                <Col xs={1.1} className='p-0 mb-1'>
                    {link.title === 'Call' ?
                        <a href={isMobile ? `tel:${link.number}` : undefined}>
                            <span
                                className={
                                    `${link.icon} bg-primary p-2 text-white rounded-circle 
                                    ${isMobile && 'cursor-pointer'} dynamic-font-size`
                                }
                            />
                        </a>
                        :
                        <a
                            target='_blank'
                            rel='noopener noreferrer'
                            href={link.link}
                        >
                            <span className={`${link.icon} bg-primary p-2 text-white rounded-circle cursor-pointer dynamic-font-size`} />
                        </a>
                    }
                </Col>
                <Col className='justify-content-start'>
                    {link.title === 'Call' ?
                        <span>
                            <div>
                                <a href={isMobile ? `tel:${link.number}` : undefined}>{link.number}</a>.
                            </div>
                            <div>Option 1, then Option 6</div>
                        </span>
                        :
                        <a
                            target='_blank'
                            rel='noopener noreferrer'
                            href={link.link}
                        >
                            <span id='help-link' className='text-primary hover-underline cursor-pointer'>
                                <span
                                    id='link-title'
                                    className='text-primary mr-1'
                                >
                                    {link.title}
                                </span>
                            </span>
                        </a>
                    }
                </Col>
            </Row>
        );
    });

    return (
        <React.Fragment>
            <div
                className='px-3 py-3 bg-light'
            >
                <div className='h2 text-primary'>Need help?</div>
                <div className='help-instructions mb-2'>
                    <div>Contact the Payroll Service Center. Monday through Friday. 7 a.m. - 5 p.m. MST/Arizona time.</div>
                </div>
                {helpLinkComponents}
            </div>
        </React.Fragment>
    );
};

export default Help;